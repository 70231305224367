


































































import Vue from 'vue'

export default Vue.extend({
  props: {
    id: String,
  },
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: {
        username: [{ required: true, message: '请填写钱包帐号' }],
        password: [{ required: true, message: '请填写钱包密码' }],
        secret: [{ required: false, message: '请填写密钥' }],
        balance: [{ required: false, message: '请填写余额' }],
        steamid: [{ required: false, message: '请填写steam id' }],
        region: [{ required: false, message: '请填写地区' }],
        message: [{ required: false, message: '请填写操作消息' }],
        remarks: [{ required: false, message: '请填写备注' }],
        enable: [{ required: true, message: '请填写启用' }],
        discount: [{ required: true, message: '请填写成本折扣' }],
      },
      model: {
        username: '',
        password: '',
        secret: '',
        balance: '',
        steamid: '',
        region: '',
        message: '',
        remarks: '',
        enable: '',
        discount: '',
      },
    }
  },
  created() {
    this.id && this.fetch()
  },
  methods: {
    async fetch() {
      this.formLoading = true
      const { data: configs } = await this.$http.get(`wallets/${this.id}`)
      this.model = Object.assign(this.model, configs)
      this.formLoading = false
    },
    async save() {
      ~(this.$refs.form as any).validate(async (valid: boolean) => {
        if (valid) {
          this.loading = true
          try {
            if (this.id) {
              await this.$http.put(`wallets/${this.id}`, this.model)
            } else {
              await this.$http.post('wallets', this.model)
            }
          } finally {
            this.loading = false
          }
          this.$router.push('/wallet/list')
          this.$message.success('保存成功')
        }
      })
    },
  },
})
